
import { Component, Prop, Vue } from "vue-property-decorator";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import Rules from "@/app/modules/_global/classes/Rules";

type Post = {
  id?: number;
  name?: string;
  description?: string;
  show_to?: string;
  upload_id?: null | number;
};

type Upload = {
  data: {
    id: number;
  };
};

@Component
export default class EditPostPage extends Vue {
  @Prop(Number) readonly postId!: number;

  valid = true;
  post: Post = {};
  postPhotoSource? = "";
  formData = new FormData();

  deletePostDialog = false;

  rules = {
    required: Rules.required
  };

  created() {
    this.fetchPostById();
  }

  fetchPostById() {
    this.$store.dispatch("posts/fetchById", this.postId).then(({ data }) => {
      this.post = {
        id: data.data.id,
        name: data.data.translation.name,
        description: data.data.translation.description,
        show_to: data.data.show_to.toString(),
        upload_id: data.data.upload_id
      };

      if (data.data.url_upload) this.postPhotoSource = data.data.url_upload;
    });
  }

  async takePicture() {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt
    });

    this.postPhotoSource = photo.webPath;

    if (photo.webPath) {
      const blob = await fetch(photo.webPath).then(r => r.blob());
      this.formData.append("file", blob);
    }
  }

  removePicture() {
    this.formData.delete("file");
    this.post.upload_id = null;
    this.postPhotoSource = "";
  }

  async update() {
    let upload: Upload;

    if (this.formData.has("file")) {
      upload = await this.$store.dispatch("posts/uploadPicture", this.formData);
      if (this.post && upload) this.post["upload_id"] = upload.data.id;
    }

    this.$store.dispatch("posts/update", this.post).then(({ data }) => {
      this.$store.commit("posts/updatePersonalData", data.data);
      this.$store.commit("posts/clearData");
      this.$router.back();
    });
  }

  deletePost() {
    this.$store.dispatch("posts/delete", this.post.id).then(() => {
      this.$store.commit("posts/setPersonalData", null);
      this.$store.commit("posts/clearData");
      this.$router.back();
    });
  }
}
